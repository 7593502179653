import React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

export interface LoadingProps {
  message?: string;
}

export const Loading: React.FC<LoadingProps> = ({ message }) => (
  <LoadingStyling>
    <CircularProgress style={{ color: '#415939' }} />
    <Typography variant="h5" className="CustomMessage">
      {message}
    </Typography>
  </LoadingStyling>
);

const LoadingStyling = styled.div<LoadingProps>`
  text-align: center;
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 125px;
  .CustomMessage {
    margin-top: 40px;
  }
`;
