import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import frame from '../../Assets/TrellisAssets/Frame.svg';
import noFrame from '../../Assets/TrellisAssets/noFrame.svg';
import { animated, useTransition } from 'react-spring';
import { trellisStyleIcon } from '../../Icons';
import { useSelector } from 'react-redux';
import { QuotePayload } from '../../models/QuotePayload';

export interface TrellisStyleProps {
  trellisStyleType: 'oriental' | 'diagonal' | 'venetian' | 'square' | null;
  setTrellisStyleType: (e: any) => void;
  setFramed: (e: any) => void;
  framed: boolean | null;
}

export const TrellisStyle: React.FC<TrellisStyleProps> = ({
  trellisStyleType,
  setTrellisStyleType,
  setFramed,
  framed,
}) => {
  const transitions = useTransition(trellisStyleType, trellisStyleType, {
    initial: null,
    from: {
      opacity: 0,
      transform: 'translate3d(100%,0,0)',
      position: 'absolute',
    },
    enter: {
      opacity: 1,
      position: 'absolute',
      transform: 'translate3d(0%,0,0)',
    },
    leave: {
      opacity: 0,
      position: 'absolute',
      transform: 'translate3d(-50%,0,0)',
    },
    config: {
      easing: (x) =>
        x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2,
      duration: 450,
    },
  });

  const trellisAngle = useSelector<QuotePayload>(
    (s) =>
      s.trellisData[s.trellisData.length && s.trellisData.length - 1].shape
        .angle,
  ) as 'left' | 'right' | null;

  const displayProduct = () => {
    return transitions.map(({ item, key, props }) => (
      <animated.div key={key} style={props} className="Animation">
        {trellisStyleIcon(item, trellisAngle, false)}
      </animated.div>
    ));
  };
  return (
    <div className="MainContent">
      <Typography variant="h6" className="HeaderText">
        Your trellis will be
      </Typography>
      <TrellisStyleStyle trellisStyleType={trellisStyleType} framed={framed}>
        <div className="Inputs">
          <div className="StyleInput">
            <Button
              onClick={() => setTrellisStyleType('venetian')}
              className="VenetianButton"
            >
              Venetian
            </Button>
            <Button
              onClick={() => setTrellisStyleType('square')}
              className="SquareButton"
            >
              Square
            </Button>
            <Button
              onClick={() => setTrellisStyleType('diagonal')}
              className="DiagonalButton"
            >
              Diagonal
            </Button>
            <Button
              onClick={() => setTrellisStyleType('oriental')}
              className="OrientalButton"
            >
              Oriental
            </Button>
          </div>
          <div className="FrameInput">
            <Button onClick={() => setFramed(true)} className="Frame">
              with a frame
            </Button>
            <Button onClick={() => setFramed(false)} className="NoFrame">
              without a frame
            </Button>
          </div>
        </div>
        <div className="AnimationContainer"> {displayProduct()}</div>

        <div className="InlineIconSelectors">
          <div className="Row">
            <span className="RowLabel">STYLES</span>
            <div className="StyleIconSelect">
              <Button
                onClick={() => setTrellisStyleType('venetian')}
                color={'success'}
              >
                {trellisStyleIcon('venetian', trellisAngle, true)}
              </Button>
              <Button
                color={'success'}
                onClick={() => setTrellisStyleType('square')}
              >
                {trellisStyleIcon('square', trellisAngle, true)}
              </Button>
              <Button
                color={'success'}
                onClick={() => setTrellisStyleType('diagonal')}
              >
                {trellisStyleIcon('diagonal', trellisAngle, true)}
              </Button>
              <Button
                color={'success'}
                onClick={() => setTrellisStyleType('oriental')}
              >
                {trellisStyleIcon('oriental', trellisAngle, true)}
              </Button>
            </div>
          </div>
          <div className="Row">
            <span className="RowLabel">FRAME OPTIONS</span>
            <div className="FrameIconSelect">
              <Button color={'success'} onClick={() => setFramed(true)}>
                <img src={frame} alt="frame" className="FrameIcon" />
              </Button>
              <Button color={'success'} onClick={() => setFramed(false)}>
                <img src={noFrame} alt="no frame" className="NoFrameIcon" />
              </Button>
            </div>
          </div>
        </div>
      </TrellisStyleStyle>
    </div>
  );
};

export default TrellisStyle;

const TrellisStyleStyle = styled.div<{ trellisStyleType: any; framed: any }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;

  .StyleInput {
    display: inline-flex;
    justify-content: space-between;
    flex-direction: row;
    font-size: 20px;

    .VenetianButton {
      font-weight: ${(props) => props.trellisStyleType === 'venetian' && 600};
      color: ${(props) =>
        props.trellisStyleType === 'venetian'
          ? props.theme.green
          : props.theme.grey};
    }

    .SquareButton {
      font-weight: ${(props) => props.trellisStyleType === 'square' && 600};
      color: ${(props) =>
        props.trellisStyleType === 'square'
          ? props.theme.green
          : props.theme.grey};
    }

    .DiagonalButton {
      font-weight: ${(props) => props.trellisStyleType === 'diagonal' && 600};
      color: ${(props) =>
        props.trellisStyleType === 'diagonal'
          ? props.theme.green
          : props.theme.grey};
    }

    .OrientalButton {
      font-weight: ${(props) => props.trellisStyleType === 'oriental' && 600};
      color: ${(props) =>
        props.trellisStyleType === 'oriental'
          ? props.theme.green
          : props.theme.grey};
    }
  }

  .FrameInput {
    display: flex;
    justify-content: space-around;
    .Frame {
      font-weight: ${(props) => props.framed && 600};
      color: ${(props) =>
        props.framed ? props.theme.green : props.theme.grey};
    }

    .NoFrame {
      font-weight: ${(props) => !props.framed && 600};
      color: ${(props) =>
        !props.framed ? props.theme.green : props.theme.grey};
    }
  }

  .StyleIconSelect {
    .VenetianIcon,
    .OrientalIcon,
    .SquareIcon,
    .DiagonalIcon {
      width: 50px;
    }

    .VenetianIcon {
      filter: ${(props) =>
        props.trellisStyleType !== 'venetian' && 'opacity(0.10)'};
    }
    .OrientalIcon {
      filter: ${(props) =>
        props.trellisStyleType !== 'oriental' && 'opacity(0.10)'};
    }
    .SquareIcon {
      filter: ${(props) =>
        props.trellisStyleType !== 'square' && 'opacity(0.10)'};
    }
    .DiagonalIcon {
      filter: ${(props) =>
        props.trellisStyleType !== 'diagonal' && 'opacity(0.10)'};
    }
  }

  .FrameIconSelect {
    .FrameIcon,
    .NoFrameIcon {
      width: 50px;
    }
    .NoFrameIcon {
      filter: ${(props) => props.framed && 'opacity(0.10)'};
    }
    .FrameIcon {
      filter: ${(props) => !props.framed && 'opacity(0.10)'};
    }
  }
`;
