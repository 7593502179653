import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import { QuotePayload, TrellisData, TrellisItem } from '../models/QuotePayload';
import { MainComponentStyle } from '../Routes';
import { env } from '../../profile';
import { createTrellisVariants, dataMassager, handleError } from '../utils';
import { FinishQuote } from './FinishQuote';
import { Header } from './Header';
import { SelectDimensions } from './SelectDimensions';
import { SelectQuantity } from './SelectQuantity';
import { SelectStyle } from './SelectStyle';

export const CONSUMER_TRELLIS_ROUTE = '/consumer/trellis';

export const ConsumerTrellisWorkflow: React.FC = () => {
  const [openSummary, setOpenSummary] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const consumerSteps = ['Dimensions', 'Styles', 'Summary'];

  const trellisData = useSelector<QuotePayload, TrellisData[]>((quotePayload) =>
    quotePayload.trellisData.filter((trellisData) => trellisData.added),
  );

  const progressStep = useSelector<QuotePayload>(
    (quotePayload) => quotePayload.stepCounter,
  ) as number;

  const transitions = useTransition(progressStep, (p) => p, {
    from: { opacity: 0, position: 'absolute', height: '100%' },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 350,
      easing: (t: number) => t * (2 - t),
    },
    reset: true,
  });

  const backToStart = () => {
    history.push('/consumer/trellis');

    dispatch({
      type: 'PROGRESS_STEP',
      reset: true,
    });
  };

  const handleFinish = async () => {
    setLoading(true);
    await createTrellisVariants({
      // Only trellis data is going in, so only TrellisItem's will come out.
      trellis: dataMassager(trellisData, [], []) as TrellisItem[],
    })
      .then((res) => {
        const variantString = res.data
          .map((trellis) => `updates[${trellis.variantId}]=${trellis.qty}`)
          .join('&');
        window.location.href = `${env?.SHOPIFY_STORE_URL}/cart/update?${variantString}`;
      })
      .catch(() => {
        setLoading(false);
        handleError('Error creating trellis variants');
      });
  };

  const renderComponent = () => {
    const renderStep = (item: number) => {
      switch (item) {
        case 0:
          return (
            <SelectDimensions
              productSelected="trellis"
              pushUrl={CONSUMER_TRELLIS_ROUTE}
            />
          );
        case 1:
          return (
            <SelectStyle productSelected="trellis" pushUrl={CONSUMER_TRELLIS_ROUTE} />
          );
        case 2:
          return (
            <SelectQuantity
              nextButtonText="Add"
              productSelected="trellis"
              pushUrl={CONSUMER_TRELLIS_ROUTE}
            />
          );
        case 3:
          return (
            <FinishQuote
              productSelected="trellis"
              backToStart={backToStart}
              setOpenSummary={setOpenSummary}
              completeButtonText="Review"
            />
          );
      }
    };

    return transitions.map(({ item, key, props }) => (
      <div className="Container" key={key}>
        <animated.div key={key} style={props} className="Animation">
          {renderStep(item)}
        </animated.div>
      </div>
    ));
  };

  return (
    <>
      <Header
        finishButtonText="Add to Cart"
        progressSteps={consumerSteps}
        productSelected="trellis"
        handleFinish={handleFinish}
        progressStep={progressStep}
        setOpenSummary={setOpenSummary}
        openSummary={openSummary}
        _handleBack={() => {
          history.goBack();
          dispatch({
            type: 'PROGRESS_STEP',
            decrement: true,
          });
        }}
        backUrl={'/consumer/trellis'}
      />
      <MainComponentStyle>
        {loading ? (
          <CircularProgress
            style={{
              height: '100px',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            color="success"
          />
        ) : (
          renderComponent()
        )}
      </MainComponentStyle>
    </>
  );
};
