import {
  QuotePayload,
  TrellisData,
  GateData,
  PanelData,
} from './models/QuotePayload';
import { ProductTypeName } from './types';

const initialState: QuotePayload = {
  email: '',
  product: 'trellis',
  trellisData: [
    {
      shape: { height1: 0, height2: 0, width: 0, angle: null },
      framed: false,
      name: 'trellis',
      quantity: 0,
      fixing: 'stainless',
      style: 'diagonal',
      added: false,
    },
  ],
  gateData: [
    {
      shape: { gateHeight: 0, gateWidth: 0, hingePlacement: 'left' },
      quantity: 0,
      name: 'gate',
      picketProfile: 'blank',
      picketSize: 'large',
      railSize: 'standard',
      style: 'square',
      added: false,
    },
  ],
  panelData: [
    {
      shape: { height: 0, width: 0 },
      name: 'panel',
      quantity: 0,
      picketSize: 'standard',
      railSize: 'large',
      picketProfile: 'blank',
      style: 'square',
      added: false,
    },
  ],
  totalItems: 0,
  stepCounter: 0,
};

interface NewEmptyProductAction {
  type: 'NEW_EMPTY_PRODUCT';
  productType: ProductTypeName;
}

interface ClearQuotesAction {
  type: 'CLEAR_QUOTES';
}

interface TotalItemsAction {
  type: 'SET_TOTAL_ITEMS';
  decrement: boolean;
}

interface NewTrellisAction {
  type: 'NEW_TRELLIS';
  trellis: TrellisData;
}

interface SetEmailAction {
  type: 'SET_EMAIL';
  email: string;
}

interface SetProductTypeAction {
  type: 'SET_PRODUCT_TYPE';
  product: ProductTypeName;
}

interface DeleteAction {
  product: ProductTypeName;
  type: 'DELETE_ITEM';
  index: number;
}

interface ChangeQuantityAction {
  product: ProductTypeName;
  type: 'CHANGE_QUANTITY';
  quantity: number;
  index: number;
}

interface ProgressStep {
  type: 'PROGRESS_STEP';
  product: ProductTypeName;
  reset: boolean;
  decrement: boolean;
  stepCounter: number;
}

interface SetFieldAction {
  type: 'SET_FIELD';
  productType: ProductTypeName;
  fieldName: string;
  value: any;
}

interface SetFieldsAction {
  type: 'SET_FIELDS';
  productType: ProductTypeName;
  fieldValue: [{ fieldName: string; value: string }];
}

type TrelliAction =
  | ProgressStep
  | NewEmptyProductAction
  | NewTrellisAction
  | TotalItemsAction
  | SetProductTypeAction
  | SetEmailAction
  | DeleteAction
  | ClearQuotesAction
  | ChangeQuantityAction
  | SetFieldAction
  | SetFieldsAction;

export const rootReducer = (
  state = initialState,
  action: TrelliAction,
): QuotePayload => {
  switch (action.type) {
    case 'PROGRESS_STEP': {
      if (action.reset) {
        return {
          ...state,
          stepCounter: 0,
        };
      }
      if (action.stepCounter) {
        return {
          ...state,
          stepCounter: action.stepCounter,
        };
      }
      if (action.decrement) {
        return {
          ...state,
          stepCounter: state.stepCounter - 1,
        };
      } else {
        return {
          ...state,
          stepCounter: state.stepCounter + 1,
        };
      }
    }

    case 'NEW_TRELLIS':
      return {
        ...state,
        trellisData: state.trellisData.concat([action.trellis]),
      };
    case 'SET_TOTAL_ITEMS':
      if (action.decrement) {
        return {
          ...state,
          stepCounter: state.totalItems - 1,
        };
      } else {
        return {
          ...state,
          stepCounter: state.totalItems + 1,
        };
      }
    case 'NEW_EMPTY_PRODUCT':
      if (action.productType === 'trellis') {
        return {
          ...state,
          trellisData: state.trellisData.concat([
            {
              shape: {
                height1: 0,
                height2: 0,
                width: 0,
              },
              name: 'trellis',
              framed: false,
              quantity: 0,
              fixing: 'stainless',
              style: 'diagonal',
              added: false,
            } as TrellisData,
          ]),
        };
      } else if (action.productType === 'gate') {
        return {
          ...state,
          gateData: state.gateData.concat([
            {
              shape: { gateHeight: 0, gateWidth: 0, hingePlacement: 'left' },
              quantity: 0,
              name: 'gate',
              picketProfile: 'blank',
              picketSize: 'standard',
              railSize: 'standard',
              style: 'square',
              added: false,
            } as GateData,
          ]),
        };
      } else {
        return {
          ...state,
          panelData: state.panelData.concat([
            {
              shape: { height: 0, width: 0 },
              quantity: 0,
              name: 'panel',
              picketSize: 'standard',
              railSize: 'standard',
              picketProfile: 'blank',
              style: 'square',
              added: false,
            } as PanelData,
          ]),
        };
      }

    case 'SET_PRODUCT_TYPE':
      return {
        ...state,
        product: action.product,
      };
    case 'SET_EMAIL':
      return {
        ...state,
        email: action.email,
      };
    case 'CLEAR_QUOTES':
      return {
        ...state,
        initialState,
      };

    case 'CHANGE_QUANTITY': {
      if (action.product === 'trellis') {
        state.trellisData[action.index].quantity = action.quantity;
        return {
          ...state,
        };
      } else if (action.product === 'gate') {
        state.gateData[action.index].quantity = action.quantity;
        return {
          ...state,
        };
      } else {
        state.panelData[action.index].quantity = action.quantity;
        return {
          ...state,
        };
      }
    }
    case 'DELETE_ITEM':
      if (action.product === 'trellis') {
        return {
          ...state,
          trellisData: state.trellisData.filter(
            (item: any, index: number) =>
              state.trellisData[index] !== state.trellisData[action.index],
          ),
        };
      } else if (action.product === 'gate') {
        return {
          ...state,
          gateData: state.gateData.filter(
            (item: any, index: number) =>
              state.gateData[index] !== state.gateData[action.index],
          ),
        };
      } else {
        return {
          ...state,
          panelData: state.panelData.filter(
            (item: any, index: number) =>
              state.panelData[index] !== state.panelData[action.index],
          ),
        };
      }

    case 'SET_FIELD': {
      const parts = action.fieldName.split('.');
      let current;
      if (action.productType === 'trellis') {
        current = state.trellisData[state.trellisData.length - 1];
      } else if (action.productType === 'gate') {
        current = state.gateData[state.gateData.length - 1];
      } else {
        current = state.panelData[state.panelData.length - 1];
      }

      let newCurrent;
      if (parts[0] === 'shape') {
        newCurrent = {
          ...current,
          shape: {
            ...current.shape,
            [parts[1]]: action.value,
          },
        };
      } else {
        newCurrent = { ...current, [parts[0]]: action.value };
      }
      if (action.productType === 'trellis') {
        return {
          ...state,
          trellisData: state.trellisData
            .slice(0, state.trellisData.length - 1)
            //@ts-ignore
            .concat([newCurrent]),
        };
      } else if (action.productType === 'gate') {
        return {
          ...state,
          gateData: state.gateData
            .slice(0, state.gateData.length - 1)
            //@ts-ignore
            .concat([newCurrent]),
        };
      } else {
        return {
          ...state,
          panelData: state.panelData
            .slice(0, state.panelData.length - 1)
            //@ts-ignore
            .concat([newCurrent]),
        };
      }
    }
    case 'SET_FIELDS': {
      let newState = state;

      action.fieldValue.map((fieldValue) => {
        const parts = fieldValue.fieldName.split('.');
        let current;

        if (action.productType === 'trellis') {
          current = newState.trellisData[newState.trellisData.length - 1];
        } else if (action.productType === 'gate') {
          current = newState.gateData[newState.gateData.length - 1];
        } else {
          current = newState.panelData[newState.panelData.length - 1];
        }

        let newCurrent;
        if (parts[0] === 'shape') {
          newCurrent = {
            ...current,
            shape: {
              ...current.shape,
              [parts[1]]: fieldValue.value,
            },
          };
        } else {
          newCurrent = { ...current, [parts[0]]: fieldValue.value };
        }

        if (action.productType === 'trellis') {
          newState = {
            ...newState,
            trellisData: state.trellisData
              .slice(0, state.trellisData.length - 1)
              //@ts-ignore
              .concat([newCurrent]),
          };
        } else if (action.productType === 'gate') {
          newState = {
            ...newState,
            gateData: state.gateData
              .slice(0, state.gateData.length - 1)
              //@ts-ignore
              .concat([newCurrent]),
          };
        } else {
          newState = {
            ...newState,
            panelData: newState.panelData
              .slice(0, newState.panelData.length - 1)
              //@ts-ignore
              .concat([newCurrent]),
          };
        }

        return newState;
      });

      return newState;
    }

    default:
      return state;
  }
};
