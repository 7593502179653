import React, { useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Input, InputBaseComponentProps } from '@mui/material';
import styled from 'styled-components';
import { gateIcon } from '../../Icons';
import { blurInput, focusInput } from '../../utils';
import { ErrorFieldType } from '../../validate-dimensions';

export interface GateDimensionsProps {
  inputProps: InputBaseComponentProps;
  gateHeight: number;
  gateWidth: number;
  hingePlacement: 'left' | 'right';
  setHingePlacement: (hingePlacement: 'left' | 'right') => void;
  setGateHeight: (gateHeight: number) => void;
  setGateWidth: (hateWidth: number) => void;
  errorField?: ErrorFieldType;
}

export const GateDimensions: React.FC<GateDimensionsProps> = ({
  inputProps,
  gateHeight,
  gateWidth,
  setGateHeight,
  setGateWidth,
  setHingePlacement,
  hingePlacement,
  errorField,
}) => {
  const displayGate = () => {
    return <div className="Icon GateIcon">{gateIcon(hingePlacement)}</div>;
  };
  const heightRef = useRef<HTMLInputElement>(null);
  const widthRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <div className="MainContent">
        <Typography variant="h6" className="HeaderText">
          Set the dimensions and hinge position of your gate
        </Typography>
        <GateDimensionsInput hingePlacement={hingePlacement}>
          <div className="HingeInput">
            <Button
              onClick={() => setHingePlacement('left')}
              className="LeftHandButton"
            >
              Left Hand Hinge
            </Button>
            <Button
              className="RightHandButton"
              onClick={() => setHingePlacement('right')}
            >
              Right Hand Hinge
            </Button>
          </div>
        </GateDimensionsInput>
        <div className="DimensionsInput">
          {displayGate()}
          <FormControl
            className={hingePlacement === 'left' ? 'RightInput' : 'LeftInput'}
            variant="outlined"
          >
            <Input
              disableUnderline={true}
              className="InputBox"
              autoComplete="off"
              required
              inputProps={{ ...inputProps, inputMode: 'numeric' }}
              id="heightInMM"
              type="number"
              inputRef={heightRef}
              onKeyDown={(e) => e.key === 'Enter' && focusInput(widthRef)}
              autoFocus
              placeholder="Height"
              error={errorField === ErrorFieldType.height1}
              value={gateHeight !== 0 && gateHeight}
              onChange={(e) =>
                setGateHeight(parseInt(e.currentTarget.value, 10))
              }
            />
            <div className="MM">mm</div>
          </FormControl>
          <FormControl className="BottomInput" variant="outlined">
            <Input
              disableUnderline={true}
              className="InputBox"
              autoComplete="off"
              required
              inputRef={widthRef}
              error={errorField === ErrorFieldType.width}
              inputProps={{ ...inputProps, inputMode: 'numeric' }}
              placeholder="Width"
              onKeyDown={(e) => e.key === 'Enter' && blurInput(widthRef)}
              id="widthInMM"
              type="number"
              value={gateWidth !== 0 && gateWidth}
              onChange={(e) =>
                setGateWidth(parseInt(e.currentTarget.value, 10))
              }
            />
            <div className="MM MM-width">mm</div>
          </FormControl>

          {hingePlacement === 'left' ? (
            <div className="LeftInput" />
          ) : (
            <div className="RightInput" />
          )}
        </div>
      </div>
    </>
  );
};

const GateDimensionsInput = styled.div<{ hingePlacement: any }>`
  text-align: center;

  .LeftHandButton {
    font-weight: ${(props) => props.hingePlacement === 'left' && 600};
    color: ${(props) =>
    props.hingePlacement === 'left' ? props.theme.green : props.theme.grey};
  }

  .RightHandButton {
    font-weight: ${(props) => props.hingePlacement === 'right' && 600};
    color: ${(props) =>
    props.hingePlacement === 'right' ? props.theme.green : props.theme.grey};
  }
`;
