import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { sendFeedbackEmail } from '../utils';
import { altButton01SX } from '../styles';
import Swal from 'sweetalert2';
import {
  Typography,
  FormControl,
  OutlinedInput,
  TextareaAutosize,
  LinearProgress,
} from '@mui/material';

/**
 * This is the Feedback modal which persists throughout the app.
 * Users can click the 'FeedbackIcon' on the top right section of the app
 * to trigger the modal
 */
export const Feedback: React.FC = () => {
  const [openFeedback, setOpenFeedback] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const renderError = (msg: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Sorry, we could not submit your feedback!',
      text: msg,
    });
  };

  const renderSuccess = (msg: string) => {
    Swal.fire({
      icon: 'success',
      title: msg,
      showConfirmButton: false,
      timer: 1000,
    });
  };

  const handleSendFeedback = async () => {
    setLoading(true);

    try {
      await sendFeedbackEmail({ email: email || null, feedback });
    } catch (err) {
      setLoading(false);
      setOpenFeedback(false); // Otherwise popup is hidden
      renderError('Please contact us at hello@genia.co.nz or 03 528 1026');
      return;
    }

    setLoading(false);
    setOpenFeedback(false);
    renderSuccess('Thank you for your feedback!');
  };

  return (
    <>
      <ModalStyle>
        <IconButton
          className="FeedbackModalButton"
          onClick={() => setOpenFeedback(true)}
          size="large"
        >
          <FeedbackIcon className="FeedbackModalIcon" />
        </IconButton>
      </ModalStyle>

      <Dialog
        style={{ maxWidth: 800, margin: '0 auto' }}
        fullScreen
        fullWidth
        onClose={() => {
          setOpenFeedback(false);
        }}
        open={openFeedback}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            className="CloseButton"
            style={{ position: 'absolute', right: 0, top: 0 }}
            onClick={() => setOpenFeedback(false)}
            size="large"
          >
            <CloseIcon style={{ fontSize: 35 }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            overflowX: 'hidden',
            maxWidth: '600px',
            alignSelf: 'center',
          }}
        >
          <FeedbackStyle>
            <Typography variant="h5" className="FeedbackHeader">
              We'd love to hear from you.
            </Typography>
            <div className="FormWrapper">
              <FormControl className="FormStyle" variant="outlined">
                <OutlinedInput
                  className="EmailInput"
                  id="email"
                  value={email}
                  type="email"
                  color={'success'}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="name@email.com"
                  endAdornment={
                    <InputAdornment position="end">
                      <EmailIcon className="EmailIcon" />
                    </InputAdornment>
                  }
                />

                <TextareaAutosize
                  required
                  id="reference"
                  className="FeedbackTextArea"
                  placeholder="Enter your feedback."
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  minRows={5}
                  maxRows={10}
                />
              </FormControl>

              {loading && <LinearProgress />}

              <Button
                className="FeedbackButton"
                sx={altButton01SX}
                disabled={loading || !feedback}
                variant="contained"
                disableElevation
                onClick={() => {
                  handleSendFeedback();
                }}
              >
                Submit Feedback
              </Button>
            </div>
          </FeedbackStyle>
        </DialogContent>
      </Dialog>
    </>
  );
};

const ModalStyle = styled.div`
  z-index: 1000;
  .FeedbackModalButton {
    .FeedbackModalIcon {
      font-size: 2.5rem;
      color: #375954;
    }
  }
  .ModalButton {
    left: 10px;
    position: absolute;
    top: 0;
    margin-top: 10px;
  }
  .QuoteItemContainer {
    margin-top: 40px;
  }
`;

const FeedbackStyle = styled.div`
  height: 100%;
  display: grid;
  align-content: center;

  .FormWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 40px;
  }

  .EmailInput {
    background-color: #fff;
  }

  .FeedbackButton {
    height: 80px;
  }

  .EmailIcon {
    color: ${(props) => props.theme.darkGreen};
  }

  .FeedbackTextArea {
    border-width: 1px;
    max-width: 800px;
    padding: 15px;
    border-radius: 5px;
    margin: 24px 0px;
  }

  .FeedbackHeader {
    color: ${(props) => props.theme.darkGreen};
    padding: 0 20px;
    font-weight: 800;
  }
`;
