import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  GateData,
  PanelData,
  QuotePayload,
  TrellisData,
} from '../models/QuotePayload';
import { ProductTypeName } from '../types';
import CheckIcon from '@mui/icons-material/Check';
import { altButton00SX, altButton01SX } from '../styles';

/**
 * This page allows a user to select whether they want to add another trellis
 * (and go back to the beginning), or get their quote via the SendEmail component
 */
export interface FinishQuoteProps {
  backToStart: () => void;
  productSelected: ProductTypeName;
  setOpenSummary: (x: boolean) => void;
  completeButtonText: string;
}

export const FinishQuote: React.FC<FinishQuoteProps> = ({
  backToStart,
  productSelected,
  setOpenSummary,
  completeButtonText,
}) => {
  const dispatch = useDispatch();

  const trellisData = useSelector<QuotePayload, TrellisData[]>((s) =>
    s.trellisData.filter((r) => r.added),
  );

  const panelData = useSelector<QuotePayload, PanelData[]>((s) =>
    s.panelData.filter((r) => r.added),
  );

  const gateData = useSelector<QuotePayload, GateData[]>((s) =>
    s.gateData.filter((r) => r.added),
  );

  const maxItems = trellisData.length + panelData.length + gateData.length >= 21;

  const productAddedPrettier = () => {
    switch (productSelected) {
      case 'trellis':
        return 'Trellis';
      case 'panel':
        return 'Panel';
      case 'gate':
        return 'Gate';
    }
  };

  const renderQuoteDecision = () => {
    return (
      <div className="MainContent">
        <div className="Spacer" />

        <div className="ProductAdded">
          <div className="SuccessIcon">
            <CheckIcon sx={{ fontSize: 100, color: '#f5f1ef' }} />
          </div>
          <Typography variant="h4" className="ProductAddedText">
            {productAddedPrettier()} Added
          </Typography>
        </div>

        <div className="FinishButtons">

          {!maxItems ? (
            <Button
              sx={altButton00SX}
              variant="contained"
              onClick={() => {
                backToStart();
                dispatch({
                  type: 'NEW_EMPTY_PRODUCT',
                  productType: productSelected,
                });
                dispatch({
                  type: 'PROGRESS_STEP',
                  reset: true,
                });
              }}
            >
              Add Another
            </Button>
          ) : (
            <div className='MaxOrderMessage'>
              <h4>max of 21 custom items per order</h4>
              <h4>add any remaining items to a new order</h4>
            </div>
          )}

          <Button
            className="Complete"
            sx={altButton01SX}
            variant="contained"
            // disableElevation
            onClick={() => {
              setOpenSummary(true);
            }}
          >
            {completeButtonText}
          </Button>
        </div>
      </div>
    );
  };
  return (
    <FinishQuoteDecisionStyle>{renderQuoteDecision()}</FinishQuoteDecisionStyle>
  );
};

export const FinishQuoteDecisionStyle = styled.div`
  height: 98%;
  display: flex;
  flex-direction: column;

  h4 {
    color: #71504d;
    font-size: 1.25rem;
    margin: 0 0 0.75rem 0;
    padding: 0;
  }

  .MaxOrderMessage {
    padding: 0 1rem 2rem 1rem;
    text-align: center;
  }

  .MainContent {
    justify-content: space-between;
    min-width: 250px;
    width: 100%;
  }

  .ProductAdded {
    display: flex;
    height: 200px;
    flex-direction: column;
    align-items: center;

    .SuccessIcon {
      background-color: #375954;
      border-radius: 50%;
      height: 125px;
      width: 125px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ProductAddedText {
      margin-top: .5rem;
      font-family: 'Georgia', serif;
      color: ${(props) => props.theme.green};
    }
  }

  .FinishButtons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
`;
