import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ProductTypeName } from '../types';
import { buttonSX } from '../styles';
import {
  getGateError,
  getPanelError,
  getTrellisError,
} from '../validate-dimensions';
import { GateDimensions } from './DimensionComponents/GateDimensions';
import { PanelDimensions } from './DimensionComponents/PanelDimensions';
import { TrellisDimensions } from './DimensionComponents/TrellisDimensions';

export interface SelectDimensionsProps {
  productSelected: ProductTypeName;
  pushUrl: string;
}

export const SelectDimensions: React.FC<SelectDimensionsProps> = ({
  productSelected,
  pushUrl,
}) => {
  const [squareWidth, setSquareWidth] = useState(0);
  const [squareHeight, setSquareHeight] = useState(0);

  const [trellisHeight1, setTrellisHeight1] = useState(0);
  const [trellisHeight2, setTrellisHeight2] = useState(0);
  const [trellisWidth, setTrellisWidth] = useState(0);

  const [hingePlacement, setHingePlacement] = useState<'left' | 'right'>(
    'right',
  );
  const [gateHeight, setGateHeight] = useState(0);
  const [gateWidth, setGateWidth] = useState(0);

  const history = useHistory();

  const dispatch = useDispatch();
  let valid = false;

  const getError = () => {
    if (productSelected === 'panel') {
      if (squareHeight && squareWidth) valid = true;
      return getPanelError(squareHeight, squareWidth);
    }
    if (productSelected === 'gate') {
      if (gateHeight && gateWidth) valid = true;
      return getGateError(gateHeight, gateWidth);
    }
    if (productSelected === 'trellis') {
      if (
        (trellisHeight1 || (trellisHeight1 && trellisHeight2)) &&
        trellisWidth
      ) {
        valid = true;
      }

      let minHeight = Math.min(trellisHeight1, trellisHeight2);
      const maxHeight = Math.max(trellisHeight1, trellisHeight2);
      // One side has "0" for height...
      if (minHeight === 0) minHeight = maxHeight;

      return getTrellisError(minHeight, maxHeight, trellisWidth);
    }

    throw new Error('Invalid product type?');
  };

  const error = getError();

  const handleNext = () => {
    if (productSelected === 'panel') {
      dispatch({
        type: 'SET_FIELDS',
        productType: productSelected,
        fieldValue: [
          { fieldName: 'shape.width', value: squareWidth },
          { fieldName: 'shape.height', value: squareHeight },
        ],
      });
    }

    if (productSelected === 'gate') {
      dispatch({
        type: 'SET_FIELDS',
        productType: productSelected,
        fieldValue: [
          { fieldName: 'shape.gateHeight', value: gateHeight },
          { fieldName: 'shape.gateWidth', value: gateWidth },
          { fieldName: 'shape.hingePlacement', value: hingePlacement },
        ],
      });
    }

    if (productSelected === 'trellis') {
      const trellisAngle: 'left' | 'right' | null =
        trellisHeight2 && trellisHeight2 > trellisHeight1
          ? 'right'
          : trellisHeight2 &&
            trellisHeight2 > 0 &&
            trellisHeight1 > trellisHeight2
            ? 'left'
            : null;

      dispatch({
        type: 'SET_FIELDS',
        productType: productSelected,
        fieldValue: [
          {
            fieldName: 'shape.height1',
            value: trellisHeight1 !== 0 ? trellisHeight1 : trellisHeight2,
          },
          { fieldName: 'shape.height2', value: trellisHeight2 },
          { fieldName: 'shape.width', value: trellisWidth },
          { fieldName: 'shape.angle', value: trellisAngle },
        ],
      });
    }

    dispatch({ type: 'PROGRESS_STEP' });
    history.push(`${pushUrl}/style`);
  };

  const inputProps = {
    min: 1,
  };


  const renderSelectDimensions = (productSelected: ProductTypeName) => {
    switch (productSelected) {
      case 'trellis':
        return (
          <TrellisDimensions
            trellisHeight1={trellisHeight1}
            trellisHeight2={trellisHeight2}
            setTrellisHeight1={setTrellisHeight1}
            setTrellisHeight2={setTrellisHeight2}
            trellisWidth={trellisWidth}
            setTrellisWidth={setTrellisWidth}
            inputProps={inputProps}
            errorField={error?.field}
          />
        );

      case 'panel':
        return (
          <PanelDimensions
            squareHeight={squareHeight}
            setSquareHeight={setSquareHeight}
            squareWidth={squareWidth}
            setSquareWidth={setSquareWidth}
            inputProps={inputProps}
            errorField={error?.field}
          />
        );
      case 'gate':
        return (
          <GateDimensions
            gateHeight={gateHeight}
            gateWidth={gateWidth}
            setGateHeight={setGateHeight}
            setGateWidth={setGateWidth}
            hingePlacement={hingePlacement}
            setHingePlacement={setHingePlacement}
            inputProps={inputProps}
            errorField={error?.field}
          />
        );
    }
  };

  return (
    <DimensionsStyle hingePlacement={hingePlacement}>
      {renderSelectDimensions(productSelected)}

      <div className="ButtonAndError">
        {error?.message && (
          <Typography
            variant="subtitle1"
            color="error"
            align="center"
            className="ErrorText"
          >
            {error.message}
          </Typography>
        )}

        {!error?.message && (
          <Button
            onClick={() => handleNext()}
            variant="contained"
            disabled={!error?.field && !valid}
            sx={buttonSX}
          >
            Next
          </Button>
        )}
      </div>
    </DimensionsStyle>
  );
};

export const DimensionsStyle = styled.div<{ hingePlacement?: string }>`
  height: 98%;
  display: flex;
  flex-direction: column;

  .MainContent {
    height: calc(100% - 150px) !important;
  }

  .ButtonAndError {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ErrorText {
    margin: 0 1em 10px 1em;
    font-size: 1.5rem;
    color: #a86752;
    font-weight: 600;
  }

  .InputBox {
    background: white;
    color: #353434;
    padding: 10px;
    text-align: center;
  }

  .TopInputs {
    display: inline-flex;
    flex-direction: row;
    .WidthInput {
      margin: 0 10px;
    }
  }

  .HingeSideInput {
    display: inline-flex;

    flex-direction: row;
    font-size: 20px;
    flex-flow: ${(props) => props.hingePlacement === 'right' && 'row-reverse'};
    .LeftHand {
      cursor: pointer;
      font-weight: ${(props) => props.hingePlacement === 'left' && 600};
      color: ${(props) =>
    props.hingePlacement === 'left' ? props.theme.green : props.theme.grey};
    }

    .RightHand {
      cursor: pointer;
      font-weight: ${(props) => props.hingePlacement === 'right' && 600};
      color: ${(props) =>
    props.hingePlacement === 'left' ? props.theme.grey : props.theme.green};
    }
  }

  .DimensionsInput {
    height: 100%;
    margin: 0 auto;
    margin-bottom: 70px;
    place-content: center;
    display: grid;
    grid-template-areas:
      'LeftInput Icon RightInput'
      '. BottomInput .';
    text-align: center;
    border-bottom: none;

    .MM {
      color: #71504d;
      font-size: 0.95rem;
      letter-spacing: 0.05rem;
      margin-top: 1px;
    }
    .MM-width {
      padding-bottom: 20px;
    }

    .Icon {
      place-self: center;
      grid-area: Icon;
      width: 130px;
      margin: 0px 10px 10px 10px;
      @media screen and (min-width: ${(props) => props.theme.bpxxs}) {
        width: 145px;
        margin: 0px 10px 10px 10px;
      }

      @media screen and (min-width: 380px) {
        width: 150px;
        margin: 15px;
      }

      @media screen and (min-width: ${(props) => props.theme.bps}) {
        width: 200px;
        margin: 15px;
      }
    }

    .GateIcon {
      transform: scale(0.7);
      @media screen and (min-width: ${(props) => props.theme.bpxxs}) {
        transform: scale(0.75);
      }

      @media screen and (min-width: 380px) {
        transform: scale(0.85);
      }

      @media screen and (min-width: ${(props) => props.theme.bps}) {
        transform: scale(1);
      }
    }

    .BottomInput,
    .RightInput,
    .LeftInput {
      text-align-last: center;
      width: 80px;
      margin: 5px;
      @media screen and (min-width: ${(props) => props.theme.bpxs}) {
        width: 100px;
      }

      .InputBox {
        font-weight: 400;
        padding: 10px 3px;
        text-align: center;
      }
    }
    .BottomInput {
      grid-area: BottomInput;
      margin: 0 auto;
    }

    .LeftInput {
      grid-area: LeftInput;
      margin: auto 0;
      padding-top: 10px;
    }
    .RightInput {
      grid-area: RightInput;
      margin: auto 0;
      padding-top: 10px;
    }
  }
`;
