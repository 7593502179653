const TRELLIS_MIN_HEIGHT = 300;
const TRELLIS_MIN_WIDTH = 300;
const OTHER_MIN_HEIGHT = 900;

const TRELLIS_MAX_DIMENSION = 2400;

const MIN_WIDTH = 700;
const GATE_MAX_WIDTH = 1800;
const GATE_MAX_HEIGHT = 1800;

const PANEL_MAX_WIDTH = 2145;

const TRELLIS_MAX_CONDITIONAL_SIZE_A = 1800;
const TRELLIS_MAX_CONDITIONAL_SIZE_B = 1200;

const PANEL_MAX_CONDITIONAL_SIZE_A = 1800;

export enum ErrorFieldType {
  width = 'width',
  height1 = 'height1',
  height2 = 'height2',
  maxHeight = 'maxHeight',
}
const PANEL_MAX_CONDITIONAL_SIZE_B = 1200;

export function getTrellisError(
  minHeight: number,
  maxHeight: number,
  width: number,
) {
  if (maxHeight > TRELLIS_MAX_DIMENSION) {
    return {
      message: `Height cannot be greater than ${TRELLIS_MAX_DIMENSION} mm`,
      field: ErrorFieldType.maxHeight,
    };
  }
  if (
    width > TRELLIS_MAX_CONDITIONAL_SIZE_A &&
    maxHeight > TRELLIS_MAX_CONDITIONAL_SIZE_B
  ) {
    return {
      message: `Width cannot be greater than ${TRELLIS_MAX_CONDITIONAL_SIZE_A} mm with a max height over ${TRELLIS_MAX_CONDITIONAL_SIZE_B} mm`,
      field: ErrorFieldType.width,
    };
  }
  if (
    maxHeight > TRELLIS_MAX_CONDITIONAL_SIZE_A &&
    width > TRELLIS_MAX_CONDITIONAL_SIZE_B
  ) {
    return {
      message: `Max height cannot be greater than ${TRELLIS_MAX_CONDITIONAL_SIZE_A} mm with a width over ${TRELLIS_MAX_CONDITIONAL_SIZE_B} mm`,
      field: ErrorFieldType.maxHeight,
    };
  }

  if (minHeight < TRELLIS_MIN_HEIGHT) {
    const heightType = minHeight === maxHeight ? 'Height' : 'Both heights';
    return {
      message: `${heightType} must be at least ${TRELLIS_MIN_HEIGHT} mm`,
      field: ErrorFieldType.maxHeight,
    };
  }
  if (width < TRELLIS_MIN_WIDTH) {
    return {
      message: `Width must be at least ${TRELLIS_MIN_WIDTH} mm`,
      field: ErrorFieldType.width,
    };
  }
  if (width > TRELLIS_MAX_DIMENSION) {
    return {
      message: `Width cannot be greater than ${TRELLIS_MAX_DIMENSION} mm`,
      field: ErrorFieldType.width,
    };
  }

  return null;
}

export function getPanelError(height: number, width: number) {
  if (height < OTHER_MIN_HEIGHT) {
    return {
      message: `Height must be at least ${OTHER_MIN_HEIGHT} mm`,
      field: ErrorFieldType.height1,
    };
  }
  if (
    height > PANEL_MAX_CONDITIONAL_SIZE_B &&
    width > PANEL_MAX_CONDITIONAL_SIZE_A
  ) {
    return {
      message: `If panel height exceeds ${PANEL_MAX_CONDITIONAL_SIZE_B} mm, the width cannot exceed ${PANEL_MAX_CONDITIONAL_SIZE_A} mm`,
      field: ErrorFieldType.width,
    };
  }

  if (width > PANEL_MAX_WIDTH) {
    return {
      message: `Panel width cannot exceed ${PANEL_MAX_WIDTH} mm`,
      field: ErrorFieldType.width,
    };
  }
  if (width < MIN_WIDTH) {
    return {
      message: `Panel width must be at least ${MIN_WIDTH} mm`,
      field: ErrorFieldType.width,
    };
  }

  return null;
}

export function getGateError(height: number, width: number) {
  if (height < OTHER_MIN_HEIGHT) {
    return {
      message: `Gate height must be at least ${OTHER_MIN_HEIGHT} mm`,
      field: ErrorFieldType.height1,
    };
  }
  if (height > GATE_MAX_HEIGHT) {
    return {
      message: `Gate height cannot exceed ${GATE_MAX_HEIGHT} mm`,
      field: ErrorFieldType.height1,
    };
  }

  if (width > GATE_MAX_WIDTH) {
    return {
      message: `Gate width cannot exceed ${GATE_MAX_WIDTH} mm`,
      field: ErrorFieldType.width,
    };
  }
  if (width < MIN_WIDTH) {
    return {
      message: `Gate width must be at least ${MIN_WIDTH} mm`,
      field: ErrorFieldType.width,
    };
  }

  return null;
}
