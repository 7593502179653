import React from 'react';
import styled from 'styled-components';
import { Feedback } from './Feedback';
import { useDispatch } from 'react-redux';
import { QuoteSummary } from './QuoteSummary';
import { ProgressStepper } from './ProgressStepper';
import RebrandLogo from '../Assets/rebrand/HeaderLogo.png';
import GetGrowingLogo from '../Assets/GetGrowingLogo.png';
import { ProductTypeName } from '../types';


export interface HeaderProps {
  handleFinish: () => void;
  progressStep: number;
  openSummary: boolean;
  setOpenSummary: (x: boolean) => void;
  productSelected: ProductTypeName;
  _handleBack: () => void;
  progressSteps: string[];
  backUrl: string;
  finishButtonText: string;
}

export const Header: React.FC<HeaderProps> = ({
  handleFinish,
  progressStep,
  openSummary,
  setOpenSummary,
  productSelected,
  _handleBack,
  progressSteps,
  backUrl,
  finishButtonText,
}) => {
  const dispatch = useDispatch();

  const backToStart = () => {
    dispatch({
      type: 'PROGRESS_STEP',
      reset: true,
    });
  };

  const renderLeftHeader = () => {
    return progressStep > 0 ? (
      <div></div>
    ) : (
      <Feedback />
    );
  };


  const consumer = window.location.pathname.includes('consumer');

  return (
    <HeaderStyle>

      {progressStep !== 7 && renderLeftHeader()}

      {(progressStep === 0 || progressStep === 7) && consumer ? (
        <div className="img-wrapper">
          <img
            src={GetGrowingLogo}
            alt="get growing logo"
            className="GeniaLogo"
          />
        </div>
      ) : (
      (progressStep === 0 || progressStep === 7) && !consumer ? (
          <div className="img-wrapper">
            <img
              src={RebrandLogo}
              alt="genia logo"
              className="GeniaLogo"
              onClick={() => backToStart()}
            />
          </div>
        ) : (<ProgressStepper steps={progressSteps} />))}

      <QuoteSummary
        finishButtonText={finishButtonText}
        handleFinish={handleFinish}
        setOpenSummary={setOpenSummary}
        productSelected={productSelected}
        openSummary={openSummary}
        progressStep={progressStep}
        backUrl={backUrl}
      />
    </HeaderStyle>
  );
};

const HeaderStyle = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;

  .img-wrapper {
    justify-self: center;
    width: 225px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  img {
    width: 100%;
  }
`;
