import { RefObject } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import client from './client';
import { CreateTrellisVariantsSchema } from './models/CreateTrellisVariantsSchema';
import { CreateGateVariantsSchema } from './models/CreateGateVariantsSchema';
import { CreatePanelVariantsSchema } from './models/CreatePanelVariantsSchema';
import { FeedbackPayload } from './models/FeedbackPayload';
import {
  GateData,
  GateItem,
  PanelData,
  PanelItem,
  QuotePayload,
  QuotePayloadSchema,
  TrellisData,
  TrellisItem,
} from './models/QuotePayload';
import { useQuery } from 'react-query';

export async function requestQuote(data: QuotePayloadSchema) {
  await client.post('/api/quote', data);
}

export async function sendFeedbackEmail(data: FeedbackPayload) {
  await client.post('/api/feedback', data);
}

export async function getTradeStores() {
  return (await client.get('/api/tradeStores')).data.result;
}

export function useTradeStores() {
  return useQuery('tradeStores', () => getTradeStores());
}

//  consumer workflow
export async function createTrellisVariants(
  data: CreateTrellisVariantsSchema,
): Promise<{ data: { qty: number; variantId: string }[] }> {
  return await client.post('/api/createTrellisVariants', data);
}

//  consumer workflow
export async function createGateVariants(
  data: CreateGateVariantsSchema,
): Promise<{ data: { qty: number; variantId: string }[] }> {
  return await client.post('/api/createGateVariants', data);
}

//  consumer workflow
export async function createPanelVariants(
  data: CreatePanelVariantsSchema,
): Promise<{ data: { qty: number; variantId: string }[] }> {
  return await client.post('/api/createPanelVariants', data);
}

export const cap = (x: string) => (x[0]?.toUpperCase() ?? '') + x.substr(1);

export const handleError = (msg: string, title?: string) => {
  Swal.fire({
    icon: 'error',
    title: title,
    text: msg,
  });
};

export function useCurrentGateData() {
  return useSelector<QuotePayload, GateData>(
    (s) => s.gateData[s.gateData.length && s.gateData.length - 1],
  );
}
export function useCurrentPanelData() {
  return useSelector<QuotePayload, PanelData>(
    (s) => s.panelData[s.panelData.length && s.panelData.length - 1],
  );
}

export function useCurrentTrellisData() {
  return useSelector<QuotePayload, TrellisData>(
    (s) => s.trellisData[s.trellisData.length && s.trellisData.length - 1],
  );
}

export const focusInput = (ref: RefObject<HTMLInputElement>) => {
  ref.current && ref.current.focus();
};
export const blurInput = (ref: RefObject<HTMLInputElement>) => {
  ref.current && ref.current.blur();
};

// Temporary hopefully 🥸
export const dataMassager = (
  trellisData: TrellisData[],
  gateData: GateData[],
  panelData: PanelData[],
) => {
  const trellisItems: TrellisItem[] = trellisData.map((item: TrellisData) => ({
    type: item.name,
    style: item.style,
    framed: item.framed,
    quantity: item.quantity,
    shape:
      item.shape.angle === null
        ? {
          height: item.shape.height1,
          width: item.shape.width,
          type: 'square',
        }
        : {
          leftHeight: item.shape.height1,
          rightHeight: item.shape.height2!,
          width: item.shape.width,
          type: 'angled',
        },
    stainless: item.fixing === 'stainless',
  }));
  const gateItems: GateItem[] = gateData.map((item: GateData) => ({
    type: item.name,
    hingePlacement: item.shape.hingePlacement,
    width: item.shape.gateWidth,
    height: item.shape.gateHeight,
    style: item.style,
    picketProfile: item.picketProfile,
    picketSize: item.picketSize,
    railSize: item.railSize,
    quantity: item.quantity,
  }));
  const panelItems: PanelItem[] = panelData.map((item: PanelData) => ({
    type: 'panel',
    width: item.shape.width,
    height: item.shape.height,
    style: item.style,
    picketProfile: item.picketProfile,
    picketSize: item.picketSize,
    railSize: item.railSize,
    quantity: item.quantity,
  }));

  return [...trellisItems, ...gateItems, ...panelItems];
};
