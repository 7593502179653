import React, { useRef } from 'react';
import FormControl from '@mui/material/FormControl';

import { InputBaseComponentProps } from '@mui/material';
import square from '../../Assets/TrellisAssets/dimensionsSquare.svg';
import angleRight from '../../Assets/TrellisAssets/dimensionsAngleRight.svg';

import Input from '@mui/material/Input';
import { animated, useTransition } from 'react-spring';
import { focusInput } from '../../utils';
import { ErrorFieldType } from '../../validate-dimensions';

export interface TrellisDimensionsProps {
  inputProps: InputBaseComponentProps;
  trellisWidth: number;
  trellisHeight1: number;
  trellisHeight2: number;
  setTrellisHeight1: (x: number) => void;
  setTrellisHeight2: (y: number) => void;
  setTrellisWidth: (z: number) => void;
  errorField?: ErrorFieldType;
}

export const TrellisDimensions: React.FC<TrellisDimensionsProps> = ({
  inputProps,
  trellisWidth,
  trellisHeight1,
  trellisHeight2,
  setTrellisHeight1,
  setTrellisHeight2,
  setTrellisWidth,
  errorField,
}) => {
  const height1 = trellisHeight1;
  const height2 = trellisHeight2;
  const height1Ref = useRef<HTMLInputElement>(null);
  const height2Ref = useRef<HTMLInputElement>(null);
  const widthRef = useRef<HTMLInputElement>(null);

  const transitions = useTransition(
    { height1, height2 },
    (k) => (k.height1 > k.height2 ? 't' : 'f'),
    {
      initial: null,
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: {
        duration: 400,
      },
    },
  );

  const renderTrellisIcon = () => {
    return transitions.map(({ item: { height1, height2 }, key, props }) => (
      <React.Fragment key={key}>
        {height2 > height1 ? (
          <animated.img
            key={key}
            style={props}
            className="Icon"
            src={angleRight}
            alt="trellis dimension"
          />
        ) : height2 > 0 && height1 > height2 ? (
          <animated.img
            key={key}
            style={{ ...props, transform: 'scaleX(-1)' }}
            className="Icon"
            src={angleRight}
            alt="trellis dimension"
          />
        ) : (
          <animated.img
            key={key}
            style={props}
            className="Icon"
            src={square}
            alt="trellis dimension"
          />
        )}
      </React.Fragment>
    ));
  };

  return (
    <>
      <div className="MainContent">
        <span className="HeaderText">Set the dimensions of your Trellis</span>
        <div className="DimensionsInput">
          <FormControl variant="outlined" className="LeftInput">
            <Input
              disableUnderline={true}
              className="InputBox"
              autoComplete="off"
              inputRef={height1Ref}
              color={'info'}
              error={
                errorField === ErrorFieldType.maxHeight &&
                trellisHeight2 < trellisHeight1
              }
              required
              type="number"
              inputProps={{ ...inputProps, inputMode: 'numeric' }}
              id="height1"
              placeholder="Height"
              autoFocus
              value={trellisHeight1 !== 0 && trellisHeight1}
              onKeyDown={(e) => e.key === 'Enter' && focusInput(widthRef)}
              onChange={(e) => setTrellisHeight1(parseInt(e.target.value, 10))}
            />
            <div className="MM">mm</div>
          </FormControl>
          <FormControl variant="outlined" className="BottomInput">
            <Input
              disableUnderline={true}
              className="InputBox"
              autoComplete="off"
              required
              error={errorField === ErrorFieldType.width}
              inputRef={widthRef}
              inputProps={{ ...inputProps, inputMode: 'numeric' }}
              color={'success'}
              id="widthInMM"
              placeholder="Width"
              type="number"
              value={trellisWidth !== 0 && trellisWidth}
              onChange={(e) => setTrellisWidth(parseInt(e.target.value, 10))}
              onKeyDown={(e) => e.key === 'Enter' && focusInput(height2Ref)}
            />
            <div className="MM MM-width">mm</div>
          </FormControl>
          <FormControl variant="outlined" className="RightInput">
            <Input
              disableUnderline={true}
              className="InputBox"
              autoComplete="off"
              required
              color={'success'}
              inputRef={height2Ref}
              error={
                errorField === ErrorFieldType.maxHeight &&
                trellisHeight1 < trellisHeight2
              }
              inputProps={{ ...inputProps, inputMode: 'numeric' }}
              id="widthInMM"
              type="number"
              value={trellisHeight2 !== 0 && trellisHeight2}
              placeholder="Height"
              onChange={(e) => setTrellisHeight2(parseInt(e.target.value, 10))}
            />
            <div className="MM">mm</div>
          </FormControl>
          {renderTrellisIcon()}
        </div>
      </div>
    </>
  );
};
