import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
// * rebrand images ==============
import TrellisPNGRebrand from '../Assets/rebrand/Trellis.png';
import GatesPNGRebrand from '../Assets/rebrand/Gates.png';
import PanelsPNGRebrand from '../Assets/rebrand/Panels.png';
// * ==============================
import { ProductTypeName } from '../types';
import { useHistory } from 'react-router-dom';

export const NewQuoteAdmin: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSelectProduct = (product: ProductTypeName) => {
    history.push('/dimensions');
    dispatch({
      type: 'SET_PRODUCT_TYPE',
      product: product,
    });
    dispatch({
      type: 'PROGRESS_STEP',
    });
  };

  return (
    <NewQuoteAdminStyle>
      <span className="LandingHeader">Add a custom product to quote</span>
      <div className="SelectProduct">
        <div className="Product">
          <img
            src={TrellisPNGRebrand}
            alt="trellis"
            className="ProductImage"
            onClick={() => handleSelectProduct('trellis')}
          />
        </div>
        <div className="Product">
          <img
            src={PanelsPNGRebrand}
            alt="pickets"
            className="ProductImage"
            onClick={() => handleSelectProduct('panel')}
          />
        </div>
        <div className="Product">
          <img
            src={GatesPNGRebrand}
            alt="gates"
            className="ProductImage"
            onClick={() => handleSelectProduct('gate')}
          />
        </div>
      </div>
    </NewQuoteAdminStyle>
  );
};

const NewQuoteAdminStyle = styled.div`
  display: flex;
  flex-direction: column;

  text-align: center;

  .LandingHeader {
    font-family: 'Georgia', serif;
    margin: 20px 0px;
    font-weight: bolder;
    font-size: 1.25rem;
    color: ${(props) => props.theme.darkGreen};
  }
  .SelectProduct {
    @media screen and (min-width: 480px) and (max-height: 690px) {
      transform: scale(0.75) !important;
      transform-origin: top !important;
    }

    @media screen and (min-width: 401px) {
      transform: scale(0.95);
      transform-origin: top;
    }
    @media screen and (max-width: 400px) {
      transform: scale(0.85);
      transform-origin: top;
    }
    @media screen and (max-width: 345px) {
      transform: scale(0.7);
      transform-origin: top;
    }
  }
  .Product {
    transition: all 0.15s ease-in-out;
    margin: .75rem 0;

    .ProductImage {
      width: 394px;
      height: 179px;
    }

    position: relative;
    :hover,
    :active {
      cursor: pointer;
      transform: scale(1.04);
    }
  }
  .Label {
    font-weight: 600;
    font-size: 30px;
    font-family: 'Neuton', serif;
    color: ${(props) => props.theme.white};
    position: absolute;
    bottom: 35px;
    left: 25px;
  }
`;
