// ? common style utilities ===============================

// next button styles for trellis, gate, and panel routes
export const buttonSX = {
  color: '#c5b79a',
  backgroundColor: '#375954',
  fontWeight: 'bold',
  fontSize: '1.25rem',
  width: '250px',
  margin: '0 auto',
  borderRadius: '.05rem',
  '&:hover': {
    color: '#f5f1ef',
    backgroundColor: '#699976',
  },
}

// email finish : submit feedback button styles
// finish quote : add another button styles
export const altButton00SX = {
  borderRadius: '0.05rem', 
  fontWeight: 'bold',
  fontSize: '1.25rem',
  width: '250px', 
  margin: '1.75rem auto', 
  color: '#c5b79a', 
  backgroundColor: '#699976', 
  '&:hover': {
    color: '#f5f1ef', 
    backgroundColor: '#3f8079'
  }, 
}

// finish quote : get quote button styles
// quote summary : no quotes back button styles
export const altButton01SX = {
  borderRadius: '0.05rem', 
  fontWeight: 'bold',
  fontSize: '1.25rem',
  width: '250px', 
  margin: '0 auto', 
  color: '#c5b79a', 
  backgroundColor: '#3f8079', 
  '&:hover': {
    color: '#f5f1ef', 
    backgroundColor: '#375954'
  },
}

// quote summary : get quote button styles
export const altButton02SX = {
  borderRadius: '0.05rem', 
  fontWeight: 'bold',
  fontSize: '1.25rem',
  width: '250px',  
  color: '#c5b79a', 
  backgroundColor: '#699976', 
  '&:hover': {
    color: '#f5f1ef', 
    backgroundColor: '#3f8079'
  }, 
}
