import { Autocomplete, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import {
  GateData,
  PanelData,
  QuotePayload,
  TrellisData,
} from '../models/QuotePayload';
import { TradeStore } from '../models/TradeStore';
import { dataMassager, requestQuote } from '../utils';
import { Loading } from './Loading';
import { altButton00SX } from '../styles';

export interface SendEmailProps {
  tradeStores: any;
  backToStart: () => void;
}

export const EmailFinish: React.FC<SendEmailProps> = ({
  tradeStores,
  backToStart,
}) => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [tradeStore, setTradeStore] = useState<null | TradeStore>(null);
  const [reference, setReference] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const trellisData = useSelector<QuotePayload, TrellisData[]>((quotePayload) =>
    quotePayload.trellisData.filter((trellis) => trellis.added),
  );

  const panelData = useSelector<QuotePayload, PanelData[]>((quotePayload) =>
    quotePayload.panelData.filter((panel) => panel.added),
  );

  const gateData = useSelector<QuotePayload, GateData[]>((quotePayload) =>
    quotePayload.gateData.filter((gate) => gate.added),
  );

  // Regex found online which validates 99% of e-mails that exist
  const regex = RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

  const renderError = (msg: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Sorry, we could not get your quote!',
      text: msg,
    });
  };

  const renderSuccess = (msg: string) => {
    Swal.fire({
      icon: 'success',
      title: msg,
      showConfirmButton: true,
    });
  };

  const handleSendEmail = async () => {
    setLoading(true);
    try {
      await requestQuote({
        name,
        phoneNumber,
        email,
        reference: reference || null,
        tradeStoreId: tradeStore?.id || null,
        items: dataMassager(trellisData, gateData, panelData),
      }).then(() => {
        setLoading(false);
        dispatch({ type: 'CLEAR_QUOTES' });
        backToStart();
        renderSuccess('You should receive your e-mail shortly.');
      });
    } catch (err) {
      setLoading(false);

      renderError('Please contact us at hello@genia.co.nz or 03 528 1026');
    }
  };

  if (loading) {
    return <Loading message="Hang tight, getting your quote ready!" />;
  }

  return (
    <EmailStyle>
      <div className="FinishMainContent">
        <Typography className="HeaderText">
          Please enter your details.
        </Typography>

        <div className="Inputs">
          <FormControl variant="outlined" className="EmailFormStyle">
            <FormHelperText style={{ marginTop: 10 }}>Name *</FormHelperText>
            <OutlinedInput
              required
              id="name"
              value={name}
              color="success"
              type="string"
              onChange={(e) => setName(e.target.value)}
              style={{ maxHeight: '50px' }}
            />
          </FormControl>

          <FormControl variant="outlined" className="EmailFormStyle">
            <FormHelperText style={{ marginTop: 10 }}>
              Phone Number *
            </FormHelperText>
            <OutlinedInput
              required
              id="phoneNumber"
              value={phoneNumber}
              color="success"
              type="string"
              onChange={(e) => setPhoneNumber(e.target.value)}
              style={{ maxHeight: '50px' }}
            />
          </FormControl>
          <FormControl variant="outlined" className="EmailFormStyle">
            <FormHelperText style={{ marginTop: 10 }}>E-Mail *</FormHelperText>
            <OutlinedInput
              required
              id="email"
              value={email}
              color="success"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="name@email.com"
              style={{ maxHeight: '50px' }}
            />
          </FormControl>
          <FormControl variant="outlined" className="EmailFormStyle">
            <FormHelperText style={{ marginTop: 10 }}>Reference</FormHelperText>
            <OutlinedInput
              color="success"
              id="reference"
              value={reference}
              onChange={(e) => setReference(e.target.value)}
              style={{ maxHeight: '50px' }}
            />
          </FormControl>
          {tradeStores && (
            <FormControl variant="outlined" className="EmailFormStyle">
              <FormHelperText style={{ marginTop: 10 }}>
                Trade Store *
              </FormHelperText>
              <Autocomplete
                autoComplete
                onChange={(_, v) => setTradeStore(v)}
                value={tradeStore}
                id="tradeStores"
                options={[...tradeStores].sort((a, b) => {
                  if (a.name < b.name) return -1;
                  if (a.name > b.name) return 1;
                  return 0;
                })}
                getOptionLabel={(x) => x.name ?? ''}
                sx={{ maxHeight: '50px' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Choose a trade store"
                    color="success"
                  />
                )}
              />
            </FormControl>
          )}

          <Button
            className="GetQuoteButton"
            sx={altButton00SX}
            variant="contained"
            disableElevation
            disabled={
              !name ||
              !phoneNumber ||
              !tradeStore ||
              trellisData.length + gateData.length + panelData.length === 0 ||
              !regex.test(email)
            }
            onClick={handleSendEmail}
          >
            Get Quote
          </Button>
        </div>
      </div>
    </EmailStyle>
  );
};

const EmailStyle = styled.div`
  display: flex;
  height: 98%;
  flex-direction: column;
  justify-content: space-between;

  .FinishMainContent {
    place-content: center;
    display: grid;
    height: 90%;
    max-width: 800px;
    margin: 0 auto;
    .HeaderText {
      margin: 0 40px 5px 40px;
      text-align: center;
      font-size: 1.2rem;
    }
  }

  .Inputs {
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    .EmailFormStyle {
      width: 80%;
      max-width: 300px;
      place-self: center;
      & > :first-child {
        margin-left: 4px;
      }
    }
  }

`;
