import React from 'react';
import styled from 'styled-components';

import { PanelData, GateData, TrellisData } from '../src/models/QuotePayload';

import venetian from '../src/Assets/TrellisAssets/Venetian.svg';
import venetianAngle from '../src/Assets/TrellisAssets/VenetianAngle.svg';
import square from '../src/Assets/TrellisAssets/Square.svg';
import squareAngle from '../src/Assets/TrellisAssets/SquareAngle.svg';
import diagonal from '../src/Assets/TrellisAssets/Diagonal.svg';
import diagonalAngle from '../src/Assets/TrellisAssets/DiagonalAngle.svg';
import oriental from '../src/Assets/TrellisAssets/Oriental.svg';
import orientalAngle from '../src/Assets/TrellisAssets/OrientalAngle.svg';

import roundTopIcon from '../src/Assets/MiscAssets/RoundTop.svg';
import invertedRoundTopIcon from '../src/Assets/MiscAssets/InvertedRoundTop.svg';
import squareTopIcon from '../src/Assets/MiscAssets/SquareTop.svg';

import galvanizedIcon from '../src/Assets/MiscAssets/Galvanized.svg';
import stainlessIcon from '../src/Assets/MiscAssets/Stainless.svg';

import hingeThin from '../src/Assets/MiscAssets/HingesThin.svg';
import hingeInstall from '../src/Assets/MiscAssets/HingeInstall.svg';

import largePickets from '../src/Assets/PanelAssets/LargePickets.svg';
import largePicketsIcon from '../src/Assets/PanelAssets/LargePicketsSmall.svg';

import standardPickets from '../src/Assets/PanelAssets/StandardPickets.svg';
import standardPicketsIcon from '../src/Assets/PanelAssets/StandardPicketsSmall.svg';

import largeGateRails from '../src/Assets/GateAssets/LargeRails.svg';
import standardGateRails from '../src/Assets/GateAssets/StandardRails.svg';

import largePanelRails from '../src/Assets/PanelAssets/LargeRails.svg';
import standardPanelRails from '../src/Assets/PanelAssets/StandardRails.svg';

import largeRailsIcon from '../src/Assets/PanelAssets/LargeRailsSmall.svg';
import standardRailsIcon from '../src/Assets/PanelAssets/StandardRailsSmall.svg';
import { ProductTypeName } from './types';

export const squareTop = (thumbnail?: boolean) => {
  return thumbnail ? (
    <img src={squareTopIcon} alt="Square Top" className="SquareTopIcon" />
  ) : (
    <img src={squareTopIcon} alt="Square Top" className="IconPreview" />
  );
};

export const roundTop = (kind: 'panel' | 'gate', thumbnail?: boolean) => {
  const src = kind === 'panel' ? invertedRoundTopIcon : roundTopIcon;
  const className = thumbnail ? 'RoundTopIcon' : 'IconPreview';
  return <img src={src} alt="Round Top" className={className} />;
};

export const galvanized = (thumbnail?: boolean) => {
  return thumbnail ? (
    <img src={galvanizedIcon} alt="Round Top" className="GalvanizedIcon" />
  ) : (
    <img src={galvanizedIcon} alt="Round Top" className="IconPreview" />
  );
};

export const stainless = (thumbnail?: boolean) => {
  return thumbnail ? (
    <img src={stainlessIcon} alt="Round Top" className="StainlessIcon" />
  ) : (
    <img src={stainlessIcon} alt="Round Top" className="IconPreview" />
  );
};

export const trellisStyleIcon = (
  style: 'diagonal' | 'venetian' | 'square' | 'oriental',
  angle: 'left' | 'right' | null,
  thumbnail?: boolean,
) => {
  switch (style) {
    case 'diagonal':
      return angle === 'left' ? (
        <img
          src={diagonalAngle}
          style={{ transform: 'scaleX(-1)' }}
          alt="Diagonal"
          className={thumbnail ? 'DiagonalIcon' : 'IconPreview'}
        />
      ) : angle === 'right' ? (
        <img
          src={diagonalAngle}
          alt="Diagonal"
          className={thumbnail ? 'DiagonalIcon' : 'IconPreview'}
        />
      ) : (
        <img
          src={diagonal}
          alt="Diagonal"
          className={thumbnail ? 'DiagonalIcon' : 'IconPreview'}
        />
      );
    case 'square':
      return angle === 'left' ? (
        <img
          src={squareAngle}
          style={{ transform: 'scaleX(-1)' }}
          alt="Square"
          className={thumbnail ? 'SquareIcon' : 'IconPreview'}
        />
      ) : angle === 'right' ? (
        <img
          src={squareAngle}
          alt="Square"
          className={thumbnail ? 'SquareIcon' : 'IconPreview'}
        />
      ) : (
        <img
          src={square}
          alt="Square"
          className={thumbnail ? 'SquareIcon' : 'IconPreview'}
        />
      );

    case 'oriental':
      return angle === 'left' ? (
        <img
          src={orientalAngle}
          style={{ transform: 'scaleX(-1)' }}
          alt="Oriental"
          className={thumbnail ? 'OrientalIcon' : 'IconPreview'}
        />
      ) : angle === 'right' ? (
        <img
          src={orientalAngle}
          alt="Oriental"
          className={thumbnail ? 'OrientalIcon' : 'IconPreview'}
        />
      ) : (
        <img
          src={oriental}
          alt="Oriental"
          className={thumbnail ? 'OrientalIcon' : 'IconPreview'}
        />
      );

    case 'venetian':
      return angle === 'left' ? (
        <img
          src={venetianAngle}
          style={{ transform: 'scaleX(-1)' }}
          alt="Venetian"
          className={thumbnail ? 'VenetianIcon' : 'IconPreview'}
        />
      ) : angle === 'right' ? (
        <img
          src={venetianAngle}
          alt="Venetian"
          className={thumbnail ? 'VenetianIcon' : 'IconPreview'}
        />
      ) : (
        <img
          src={venetian}
          alt="Venetian"
          className={thumbnail ? 'VenetianIcon' : 'IconPreview'}
        />
      );

    default:
      return (
        <img
          src={venetian}
          alt="Venetian"
          className={thumbnail ? 'VenetianIcon' : 'IconPreview'}
        />
      );
  }
};

export const gateIcon = (
  hingePlacement: 'left' | 'right',
  top?: 'square' | 'round',
) => {
  return (
    <GateIconStyle hingePlacement={hingePlacement}>
      <img className="HingesIcon" src={hingeThin} alt="Hinge" />
      <img className="HingeInstallIcon" src={hingeInstall} alt="HingeInstall" />
      <img
        className="BaseIcon"
        src={top === 'square' ? squareTopIcon : roundTopIcon}
        alt="Left Hinge"
      />
    </GateIconStyle>
  );
};

export const rails = (
  productSelected: ProductTypeName,
  railSize: 'standard' | 'large',
  picketSize?: 'standard' | 'large',
  hingePlacement?: 'left' | 'right',
  thumbnail?: boolean,
) => {
  return (
    <GateRailIconStyle hingePlacement={hingePlacement} thumbnail={thumbnail}>
      {hingePlacement && (
        <img className="HingesIconRails" src={hingeThin} alt="Hinge" />
      )}
      <img
        className="RailsIcon"
        src={
          railSize === 'standard'
            ? productSelected === 'gate'
              ? standardGateRails
              : standardPanelRails
            : productSelected === 'gate'
            ? largeGateRails
            : largePanelRails
        }
        alt="Gate Rail"
      />
      <img
        className="BaseIcon"
        src={picketSize === 'standard' ? standardPickets : largePickets}
        alt="Standard Pickets"
      />
    </GateRailIconStyle>
  );
};

export const displaySummaryIcon = (
  productSelected: TrellisData | GateData | PanelData,
) => {
  if (productSelected.name === 'trellis') {
    return (
      <SummaryIconStyle productSelected={productSelected.name}>
        <div className="LeftInput">
          <span className="Value">{productSelected.shape.height1}</span>
          <span className="mm">mm</span>
        </div>

        <div className="RightInput">
          <span className="Value">
            {productSelected.shape.height2
              ? productSelected.shape.height2
              : productSelected.shape.height1}
          </span>
          <span className="mm">mm</span>
        </div>
        <div className="BottomInput">
          <span className="Value">{productSelected.shape.width}</span>
          <span className="mm">mm</span>
        </div>

        {trellisStyleIcon(
          productSelected.style,
          productSelected.shape.angle,
          false,
        )}
      </SummaryIconStyle>
    );
  }
  return (
    <SummaryIconStyle productSelected={productSelected.name}>
      <div className="LeftInput">
        <span className="Value">
          {productSelected.name === 'gate'
            ? productSelected.shape.gateHeight
            : productSelected.shape.height}
        </span>
        <span className="mm">mm</span>
      </div>

      <div className="RightInput" style={{ visibility: 'hidden' }}>
        <span className="Value"></span>
        <span className="mm">mm</span>
      </div>
      <div className="BottomInput">
        <span className="Value">
          {productSelected.name === 'gate'
            ? productSelected.shape.gateWidth
            : productSelected.shape.width}
        </span>
        <span className="mm">mm</span>
      </div>

      {productSelected.name === 'gate'
        ? rails(
            productSelected.name,
            productSelected.railSize,
            productSelected.picketSize,
            productSelected.shape.hingePlacement,
          )
        : rails(
            productSelected.name,
            productSelected.railSize,
            productSelected.picketSize,
          )}
    </SummaryIconStyle>
  );
};

export const railThumbnails = (
  iconType: 'standardRail' | 'largeRail' | 'standardPicket' | 'largePicket',
) => {
  switch (iconType) {
    case 'largePicket':
      return (
        <img
          src={largePicketsIcon}
          alt="Large Picket"
          className="LargePicketIcon"
        />
      );
    case 'standardPicket':
      return (
        <img
          src={standardPicketsIcon}
          alt="Standard Picket"
          className="StandardPicketIcon"
        />
      );
    case 'largeRail':
      return (
        <img src={largeRailsIcon} alt="Large Rail" className="LargeRailIcon" />
      );
    case 'standardRail':
      return (
        <img
          src={standardRailsIcon}
          alt="Standard Rail"
          className="StandardRailIcon"
        />
      );
  }
};

export const GateIconStyle = styled.div<{ hingePlacement: 'left' | 'right' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .HingesIcon {
    position: absolute;
    transform: ${(props) =>
      props.hingePlacement === 'left'
        ? 'translateX(-105px) scaleX(-1);'
        : 'translateX(105px)'};
  }

  .HingeInstallIcon {
    position: absolute;
    transform: ${(props) => props.hingePlacement === 'right' && 'scaleX(-1)'};
  }
`;

export const GateRailIconStyle = styled.div<{
  hingePlacement?: 'left' | 'right';
  thumbnail?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .RailsIcon {
    position: absolute;
    z-index: 1;
  }

  .RailsIcon,
  .BaseIcon {
    height: ${(props) => props.thumbnail && '70px !important'};
    width: ${(props) => props.thumbnail && '70px !important'};
    margin: ${(props) => props.thumbnail && '0px !important'};
    transform: ${(props) => props.hingePlacement === 'right' && 'scaleX(-1)'};
  }

  .BaseIcon {
    z-index: 0;
  }

  .RailsIcon,
  .BaseIcon {
    width: 130px;
    margin: 0px 10px 10px 10px;
    @media screen and (min-width: ${(props) => props.theme.bpxxs}) {
      width: 145px;
      margin: 0px 10px 10px 10px;
    }

    @media screen and (min-width: 380px) {
      width: 150px;
      margin: 15px;
    }

    @media screen and (min-width: ${(props) => props.theme.bps}) {
      width: 175px;

      margin: 10px 15px;
    }
  }

  .HingesIconRails {
    position: absolute;
    width: ${(props) => props.thumbnail && '5px !important'};
    transform: ${(props) =>
      props.hingePlacement === 'left'
        ? props.thumbnail
          ? 'translateX(-39px) scaleX(-1) !important;'
          : 'translateX(-105px) scaleX(-1);'
        : props.thumbnail
        ? 'translateX(39px) !important;'
        : 'translateX(105px) '};

    @media screen and (min-width: 1px) {
      transform: ${(props) =>
        props.hingePlacement === 'left'
          ? 'translateX(-74px) scaleX(-1);'
          : 'translateX(74px) '};

      width: 10px;
    }

    @media screen and (min-width: ${(props) => props.theme.bpxxs}) {
      width: 10px;
      transform: ${(props) =>
        props.hingePlacement === 'left'
          ? 'translateX(-80px) scaleX(-1);'
          : 'translateX(80px) '};
    }

    @media screen and (min-width: 380px) {
      width: 11px;
      transform: ${(props) =>
        props.hingePlacement === 'left'
          ? 'translateX(-83px) scaleX(-1);'
          : 'translateX(83px) '};
    }

    @media screen and (min-width: ${(props) => props.theme.bps}) {
      width: 15px;
      transform: ${(props) =>
        props.hingePlacement === 'left'
          ? 'translateX(-98px) scaleX(-1);'
          : 'translateX(98px) '};
    }
  }
`;

export const SummaryIconStyle = styled.div<{
  productSelected: ProductTypeName;
}>`
  place-content: center;
  display: grid;
  grid-template-areas:
    'LeftInput Icon RightInput'
    '. BottomInput .';

  .Icon {
    width: 200px;
    grid-area: Icon;
    margin: 10px;
    color: '#375954'; 
  }
  .BaseIcon,
  .IconPreview {
    margin: 10px;
  }

  .BottomInput {
    grid-area: BottomInput;
    place-self: center;
  }

  .LeftInput {
    grid-area: LeftInput;
    transform: ${(props) =>
      props.productSelected === 'gate' && 'translateX(-15px)'};
  }
  .RightInput {
    grid-area: RightInput;
  }
  .mm {
    color: #699976;
    position: relative;
    top: -8px;
  }
  .Value {
    color: #353434;
    font-weight: 600;
  }

  .BottomInput,
  .RightInput,
  .LeftInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
    max-width: 150px;
    font-size: 1.05rem;
  }
`;
