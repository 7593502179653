import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { CaptureConsole, Offline } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import 'sweetalert2/dist/sweetalert2.css';
import './global.css';
import Routes from './Routes';
import { env } from '../profile';
import { store } from './store';

const history = createBrowserHistory();
const queryClient = new QueryClient();

const materialUITheme = createTheme({
  typography: {
    fontFamily: "'Arial', sans-serif",
  },
});

const tagManagerArgs = {
  gtmId: 'GTM-PKCWVQP',
};

TagManager.initialize(tagManagerArgs);

const theme = {
  green: '#699976',
  darkGreen: '#375954',
  white: '#f5f1ef',
  grey: '#d0caa9',
  red: '#a86752',
  beigeGreen: '#d0caa9',
  mobileHeight: '98%',
  desktopHeight: '94%',
  //breakpoints
  bpxxs: '340px',
  bpxs: '420px',
  bps: '480px',
  bpm: '768px',
  bpl: '1024px',
  bpxl: '1200px',
  bpxxl: '1600px',
};

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://edcbbcbdcaf8452694012453f5f13057@o925760.ingest.sentry.io/5950059',
    release: `trelli@${env?.VERSION}`,
    environment: env?.RELEASE_PROFILE,
    normalizeDepth: 10,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', 'api-trelli.genia.co.nz'],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new CaptureConsole({
        levels: ['warn', 'error'],
      }),
      new Offline(),
    ],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary showDialog>
      <ReduxProvider store={store as any}>
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={materialUITheme}>
            <ThemeProvider theme={theme}>
              <QueryClientProvider client={queryClient}>
                <Router>
                  <Routes />
                </Router>
              </QueryClientProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </ReduxProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
