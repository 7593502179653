import { version } from './package.json';


const local = {
  VERSION: version,
  RELEASE_PROFILE: 'local',
  SERVER_URI: 'http://localhost:8080',
  SHOPIFY_STORE_URL: 'https://mlc-test-store.myshopify.com'
};

const development = {
  VERSION: version,
  RELEASE_PROFILE: 'development',
  SERVER_URI: 'https://dev-trelli-api.dev01.genia.co.nz',
  SHOPIFY_STORE_URL: 'https://mlc-test-store.myshopify.com'
};

const staging = {
  VERSION: version,
  RELEASE_PROFILE: 'staging',
  SERVER_URI: 'https://staging-trelli-api.genia.co.nz',
  SHOPIFY_STORE_URL: 'https://mlc-test-store.myshopify.com'
};

const production = {
  VERSION: version,
  RELEASE_PROFILE: 'production',
  SERVER_URI: 'https://api.trelli.co.nz',
  SHOPIFY_STORE_URL: 'https://getgrowing.nz',
};

export const env = (() => {
  if (typeof window !== 'undefined') {
    const url = window.location.href;

    if (url.includes('localhost')) return local;
    if (url.includes('dev-trelli.dev01.genia.co.nz')) return development;
    if (url.includes('staging-trelli.genia.co.nz')) return staging;

    return production;
  }
})();