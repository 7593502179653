import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';
import { gateIcon, roundTop, squareTop } from '../../Icons';
import { ProductTypeName } from '../../types';
import { useCurrentGateData } from '../../utils';

export interface GatePanelStylesProps {
  productSelected: ProductTypeName;
  panelGateStyle: 'square' | 'round';
  setPanelGateStyle: (e: any) => void;
}

export const GatePanelStyles: React.FC<GatePanelStylesProps> = ({
  productSelected,
  panelGateStyle,
  setPanelGateStyle,
}) => {
  const gateData = useCurrentGateData();

  const transitions = useTransition(panelGateStyle, panelGateStyle, {
    initial: null,
    from: {
      opacity: 0,
      position: 'absolute',
    },
    enter: {
      opacity: 1,
      position: 'absolute',
    },
    leave: {
      opacity: 0,
      position: 'absolute',
    },
    config: {
      easing: (x) =>
        x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2,
      duration: 300,
    },
  });
  const renderIcon = (item: any) => {
    switch (item) {
      case 'round':
        return productSelected === 'panel'
          ? roundTop('panel')
          : gateIcon(gateData.shape.hingePlacement, 'round');

      case 'square':
        return productSelected === 'panel'
          ? squareTop()
          : gateIcon(gateData.shape.hingePlacement, 'square');
    }
  };
  const displayProduct = () => {
    return transitions.map(({ item, key, props }) => (
      <animated.div key={key} style={props} className="Animation">
        {renderIcon(item)}
      </animated.div>
    ));
  };

  return (
    <div className="MainContent">
      <Typography variant="h6" className="HeaderText">
        Set the style of your
        {productSelected === 'panel' ? ' panel' : ' gate'}
      </Typography>
      <GatePanelStylesStyle panelGateStyle={panelGateStyle}>
        <div className="StyleInput">
          <Button
            onClick={() => setPanelGateStyle('square')}
            className="SquareTopButton"
          >
            Square Top
          </Button>
          <Button
            onClick={() => setPanelGateStyle('round')}
            className="RoundTopButton"
          >
            Round Top
          </Button>
        </div>
        <div className="AnimationContainer"> {displayProduct()}</div>

        <div className="InlineIconSelectors">
          <div className="Row">
            <span className="RowLabel">STYLES</span>
            <div className="StyleIconSelect">
              <Button onClick={() => setPanelGateStyle('square')}>
                {squareTop(true)}
              </Button>
              <Button onClick={() => setPanelGateStyle('round')}>
                {roundTop(
                  productSelected === 'panel' ? 'panel' : 'gate',
                  true,
                )}
              </Button>
            </div>
          </div>
        </div>
      </GatePanelStylesStyle>
    </div>
  );
};

export default GatePanelStyles;

const GatePanelStylesStyle = styled.div<{ panelGateStyle: any }>`
  justify-content: space-around;
  height: inherit;
  display: flex;
  flex-direction: column;

  .StyleInput {
    display: inline-flex;
    justify-content: space-around;
    flex-direction: row;
    font-size: 20px;

    .SquareTopButton {
      font-weight: ${(props) => props.panelGateStyle === 'square' && 600};
      color: ${(props) =>
    props.panelGateStyle === 'square'
      ? props.theme.green
      : props.theme.grey};
    }

    .RoundTopButton {
      font-weight: ${(props) => props.panelGateStyle === 'round' && 600};
      color: ${(props) =>
    props.panelGateStyle === 'round'
      ? props.theme.green
      : props.theme.grey};
    }
  }

  .StyleIconSelect {
    .SquareTopIcon,
    .RoundTopIcon {
      width: 50px;
    }

    .SquareTopIcon {
      filter: ${(props) => props.panelGateStyle !== 'square' && 'opacity(0.1)'};
    }
    .RoundTopIcon {
      filter: ${(props) => props.panelGateStyle !== 'round' && 'opacity(0.1)'};
    }
  }
`;
