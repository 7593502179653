import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ProductTypeName } from '../types';
import { GatePanelStyles } from './StyleComponents/GatePanelStyles';
import { TrellisStyle } from './StyleComponents/TrellisStyle';
import { buttonSX } from '../styles';

/** This component is for selecting a style, and whether it has a frame or not */
export interface StyleAdminProps {
  productSelected: ProductTypeName;
  pushUrl: string;
}

export const SelectStyle: React.FC<StyleAdminProps> = ({
  productSelected,
  pushUrl,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [framed, setFramed] = useState<boolean | null>(true);
  const [panelGateStyle, setPanelGateStyle] = useState<'square' | 'round'>(
    'square',
  );
  const [trellisStyleType, setTrellisStyleType] = useState<
    'oriental' | 'diagonal' | 'venetian' | 'square' | null
  >('venetian');

  const renderSelectStyle = () => {
    return productSelected === 'trellis' ? (
      <TrellisStyle
        setTrellisStyleType={setTrellisStyleType}
        setFramed={setFramed}
        trellisStyleType={trellisStyleType}
        framed={framed}
      />
    ) : (
      <GatePanelStyles
        productSelected={productSelected}
        setPanelGateStyle={setPanelGateStyle}
        panelGateStyle={panelGateStyle}
      />
    );
  };

  const handleNext = () => {
    if (productSelected === 'trellis') {
      dispatch({
        type: 'SET_FIELDS',
        productType: productSelected,
        fieldValue: [
          { fieldName: 'style', value: trellisStyleType },
          { fieldName: 'framed', value: framed },
        ],
      });
      history.push(`${pushUrl}/quantity`);
    } else {
      dispatch({
        type: 'SET_FIELD',
        productType: productSelected,
        fieldName: 'style',
        value: panelGateStyle,
      });
      history.push(`${pushUrl}/profile`);
    }

    dispatch({
      type: 'PROGRESS_STEP',
    });
  };

  return (
    <SelectStyleStyle panelGateStyle={panelGateStyle}>
      {renderSelectStyle()}
      <Button
        onClick={() => handleNext()}
        variant="contained"
        sx={buttonSX}
      >
        Next
      </Button>
    </SelectStyleStyle>
  );
};

export const SelectStyleStyle = styled.div<{ panelGateStyle?: string }>`
  overflow-x: hidden;
  height: 98%;
  display: flex;
  flex-direction: column;

  .StyleTypeIcons {
    display: flex;
    flex-direction: column;
    align-items: center;

    .SubStyleTypeIcons {
      display: inline-flex;
      flex-direction: row;
      transform: scale(0.5);
      flex-flow: ${(props) =>
        props.panelGateStyle === 'square' && 'row-reverse'};
    }
  }

  .stainless-yes:hover {
    background-color: #5f9c43 !important;
  }

  .FrameContainer {
    width: 140px;
    margin: 0 auto;
    transform: scale(0.9);
    :hover {
      cursor: pointer;
      transform: scale(1);
    }
  }

  .BackButton {
    margin: 0 auto;
  }

  .TrellisIcons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
  }

  .WidthIcon {
    margin-right: 10px;
    transform: translateY(-10px);
    transform: rotate(90deg);
    font-size: 90px;
    margin-top: -50px;
  }
  .SquareContainer,
  .AngleContainer {
    :hover {
      cursor: pointer;
    }
  }
  .HeaderText {
    margin: 0;
  }

  .FrameText {
    margin-top: 5px;
  }
  .TopFrameText {
    margin-top: 5px;
  }
`;
